import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import ShowcaseItem from "./showcase-item";

import * as styles from "../styles/components/showcase-list.module.css";

const ShowcaseList = (props) => {

  const {
    showcase
  } = props;

  let featured = false;
  let alreadyFeatured = false;

  return (
    <div className={styles.root}>
      <Container>
        <div className="grid">
          {showcase.map(({node: showcase}) => {

            if(showcase.featured && alreadyFeatured === false) {
              featured = true;
              alreadyFeatured = true;
            }
            else {
              featured = false;
            }

            return (
              <div key={showcase.id} className={cn("col-12", featured ? styles.featured : "col-md-6 col-xl-4", styles.item)}>
                <ShowcaseItem showcase={showcase} featured={featured} />
              </div>
            )
          })}
        </div>
      </Container>
    </div>
  );
}

export default ShowcaseList;
