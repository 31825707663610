import React from "react";
import { Link } from "gatsby";
import Container from "./container";
import Icon from "./icon";
import { buildImageObj, cn } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/call-to-action.module.css";
import { imageUrlFor } from "../lib/image-url";

const CallToAction = (props) => {
  const {
    title,
    _rawDescription,
    button,
    bg
  } = props;
  let imageUrl = bg && imageUrlFor(buildImageObj(bg)).auto("format").url()
  const backgroundImageStyle = bg ? {
    backgroundImage: `url(${imageUrl})`,
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  } : {};

  return (
    <div className={cn(styles.root, "color-palette-bg-dark", bg?.asset ? styles.extraRootPadding:'' )} style={backgroundImageStyle} >
      {bg && <div className={styles.overlay}></div>}
      <Container>
        <div className={styles.contentWrap}>
          {title && (<h5 className={cn(styles.title, "color-palette-color")}>{title}</h5>)}
          {_rawDescription && (<div className={cn("entry-content", styles.content)}><PortableText blocks={_rawDescription} /></div>)}
        </div>
        {button && button.url && (
          <>
            {button.blank ? (
              <a href={button.url} className={cn("btn", button.secondaryButton ? "secondary color-palette-color color-palette-pseudo-bg-dark color-palette-svg-stroke" : "color-palette-pseudo-bg")} target="_blank" rel="noreferrer">
                {button.text}
                <Icon symbol="arrow-right" />
              </a>
            ) : (
              <Link to={button.url} className={cn("btn", button.secondaryButton ? "secondary color-palette-color color-palette-pseudo-bg-dark color-palette-svg-stroke" : "color-palette-pseudo-bg")}>
                {button.text}
                <Icon symbol="arrow-right" />
              </Link>
            )}
          </>
        )}
      </Container>
    </div>
  );
}

export default CallToAction;