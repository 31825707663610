import React from "react";
import format from "date-fns/format";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj, getNewsUrl, getLibraryUrl } from "../lib/helpers";
import { Link } from "gatsby";

import * as styles from "../styles/components/post-item.module.css";

const PostItem = (props) => {

  const {
    type,
    post
  } = props;

  return (
    <div className={styles.root}>
      {post.thumbnail && post.thumbnail.asset && (
        <div className={styles.image}>
          <Link className={styles.link} to={type === 'libraryReference' ? getLibraryUrl(post.slug) : getNewsUrl(post.slug)}>
            <img
              src={imageUrlFor(buildImageObj(post.thumbnail))
                .auto("format")
                .url()}
              alt={post.thumbnail.alt}
            />
          </Link>
        </div>
      )}
      <div className={styles.text}>
        <div className={styles.meta}>
          {type === 'libraryReference' ? (
            <>
              {post.types.slice(0, 1).map(({libraryType, _key}) => (
                <span key={_key}>{libraryType.title}</span>
              ))}
            </>
          ) : (
            <>
              <span>News</span>
              {post.publicationDate && (
                <span className={styles.newsDate}>{format(new Date(post.publicationDate).setDate(new Date(post.publicationDate).getDate() + 1), "MM.dd.yy")}</span>
              )}
            </>
          )}
        </div>

        {post.title && (
          <h3 className={styles.title}><Link className={styles.link} to={type === 'libraryReference' ? getLibraryUrl(post.slug) : getNewsUrl(post.slug)}>{post.title}</Link></h3>
        )}
      </div>
    </div>
  );
}

export default PostItem;
