import React from "react";
import { imageUrlFor } from "../lib/image-url";
import { cn, buildImageObj, getShowcaseUrl, getShowcaseCategoryUrl } from "../lib/helpers";
import { Link } from "gatsby";
import Icon from "./icon";
import Svg from 'react-inlinesvg';

import * as styles from "../styles/components/showcase-item.module.css";

const ShowcaseItem = (props) => {

  const {
    showcase,
    featured
  } = props;

  return (
    <div className={styles.root}>
      {featured ? (
        <>
          <div className={cn(styles.featured, "m-hide")}>
            <div className={cn("grid", styles.grid)}>
              <div className={cn("col-12 col-lg-6 col-xl-5", styles.text)}>

                <div className={cn("grid justify-between align-center", styles.partner)}>
                  <div className="col-auto">
                    <div className="h7">
                      <div className={styles.label}>{showcase.partner && showcase.partner.type && showcase.partner.type.title + " "}Partner</div>
                      {showcase.partner && showcase.partner.name}
                    </div>
                  </div>
                  <div className="col-auto">
                    {showcase.partner && showcase.partner.logo && showcase.partner.logo.asset && (
                      <div>
                        {showcase.partner.logo.asset.extension === 'svg' ? (
                          <Svg 
                            src={showcase.partner.logo.asset.url} 
                            description={showcase.partner.logo.alt ? showcase.partner.logo.alt : showcase.partner.name}
                            uniquifyIDs={true}
                          />
                        ) : (
                          <img
                            src={imageUrlFor(buildImageObj(showcase.partner.logo))
                              .auto("format")
                              .url()}
                            alt={showcase.partner.logo.alt ? showcase.partner.logo.alt : showcase.partner.name}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {showcase.categories && showcase.categories.length > 0 && (
                  <div className={styles.meta}>
                    {showcase.categories.slice(0, 1).map(({showcaseCategory, _key}) => (
                      <Link key={_key} to={getShowcaseCategoryUrl(showcaseCategory.slug)}>{showcaseCategory.title}</Link>
                    ))}
                  </div>
                )}

                {showcase.title && (
                  <h2 className={cn("h4", styles.title)}>{showcase.title}</h2>
                )}

                <Link className={styles.link} to={getShowcaseUrl(showcase.slug)}>
                  <span className="screen-reader-text">View Details</span>
                </Link>

                <span className={cn("link", styles.viewLink)}>
                  View Showcase<Icon symbol="arrow-right" />
                </span>
              </div>

              <div className={cn("col-12 col-lg-6 col-xl-7", styles.image)}>
                {showcase.thumbnail && showcase.thumbnail.asset && (
                  <img
                    src={imageUrlFor(buildImageObj(showcase.thumbnail))
                      .auto("format")
                      .url()}
                    alt={showcase.thumbnail.alt}
                  />
                )}
              </div>
            </div>
          </div>

          <div className={cn(styles.regular, "d-hide")}>
            {showcase.thumbnail && showcase.thumbnail.asset && (
              <div className={styles.image}>
                <Link className={styles.link} to={getShowcaseUrl(showcase.slug)}>
                  <img
                    src={imageUrlFor(buildImageObj(showcase.thumbnail))
                      .auto("format")
                      .url()}
                    alt={showcase.thumbnail.alt}
                  />
                </Link>
              </div>
            )}
            <div className={styles.text}>
              {showcase.categories && showcase.categories.length > 0 && (
                <div className={styles.meta}>
                  {showcase.categories.slice(0, 1).map(({showcaseCategory, _key}) => (
                    <Link key={_key} to={getShowcaseCategoryUrl(showcaseCategory.slug)}>{showcaseCategory.title}</Link>
                  ))}
                </div>
              )}

              {showcase.title && (
                <h2 className={styles.title}><Link className={styles.link} to={getShowcaseUrl(showcase.slug)}>{showcase.title}</Link></h2>
              )}

              <Link className={cn("link", styles.viewLink)} to={getShowcaseUrl(showcase.slug)}>
                View Showcase<Icon symbol="arrow-right" />
              </Link>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.regular}>
          {showcase.thumbnail && showcase.thumbnail.asset && (
            <div className={styles.image}>
              <Link className={styles.link} to={getShowcaseUrl(showcase.slug)}>
                <img
                  src={imageUrlFor(buildImageObj(showcase.thumbnail))
                    .auto("format")
                    .url()}
                  alt={showcase.thumbnail.alt}
                />
              </Link>
            </div>
          )}
          <div className={styles.text}>
            {showcase.categories && showcase.categories.length > 0 && (
              <div className={styles.meta}>
                {showcase.categories.slice(0, 1).map(({showcaseCategory, _key}) => (
                  <Link key={_key} to={getShowcaseCategoryUrl(showcaseCategory.slug)}>{showcaseCategory.title}</Link>
                ))}
              </div>
            )}

            {showcase.title && (
              <h2 className={styles.title}><Link className={styles.link} to={getShowcaseUrl(showcase.slug)}>{showcase.title}</Link></h2>
            )}

            <Link className={cn("link", styles.viewLink)} to={getShowcaseUrl(showcase.slug)}>
              View Showcase<Icon symbol="arrow-right" />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShowcaseItem;
