// extracted by mini-css-extract-plugin
export var featured = "showcase-item-module--featured--f1685";
export var grid = "showcase-item-module--grid--43ec7";
export var image = "showcase-item-module--image--9dd32";
export var label = "showcase-item-module--label--78dda";
export var link = "showcase-item-module--link--ec1b3";
export var meta = "showcase-item-module--meta--664ba";
export var partner = "showcase-item-module--partner--173b6";
export var regular = "showcase-item-module--regular--3af18";
export var root = "showcase-item-module--root--14a90";
export var text = "showcase-item-module--text--57b86";
export var title = "showcase-item-module--title--0fde5";
export var viewLink = "showcase-item-module--viewLink--2be0e";