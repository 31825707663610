import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import PostItem from "./post-item";

import * as styles from "../styles/components/news-section.module.css";

const LatestPosts = (props) => {

  const {
    dPosts,
    mPosts
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <h2 className={styles.title}>Latest from Pureweb</h2>
        <div className="grid">
          {(mPosts && mPosts.length > 0) ? (
            <>
              {mPosts.map(node => {
                return (
                  <div key={node._key} className={cn("col-12 col-md-6 col-xl-4", styles.item)}>
                    <PostItem type={node._type} post={node._type === "libraryReference" ? node.library : node.news} />
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {dPosts.map(({node: news}) => (
                <div key={news.id} className={cn("col-12 col-md-6 col-xl-4", styles.item)}>
                  <PostItem type="newsReference" post={news} />
                </div>
              ))}
            </>
          )}
        </div>
      </Container>
    </div>
  );
}

export default LatestPosts;
