import React from "react";
import { Link } from "gatsby";
import Container from "./container";
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import Svg from 'react-inlinesvg';
import { getPartnerUrl } from "../lib/helpers";

import * as styles from "../styles/components/fc-logos.module.css";

const Partners = (props) => {

  const {
    _key,
    title,
    partners
  } = props;

  return (
    <div className={cn(styles.root, "color-palette-border-light")} key={_key}>
      <Container>
        {title && (<h2 className={cn("h7 color-palette-color", styles.title)}>{title}</h2>)}
        {partners && partners.length > 0 && (
          <div className={cn("grid align-center justify-center color-palette-svg-fill", styles.items)}>
            {partners.map(({ partner, _key }) => {
              return (
                <div key={_key} className={cn("col-6 col-md-auto", styles.item)}>
                  {partner.logo && partner.logo.asset && (
                    <>
                      {partner.slug ? (
                        <Link to={getPartnerUrl(partner.slug)}>
                          {partner.logo.asset.extension === 'svg' ? (
                            <Svg 
                              src={partner.logo.asset.url} 
                              description={partner.logo.alt ? partner.logo.alt : partner.name}
                              uniquifyIDs={true}
                            />
                          ) : (
                            <img
                              src={imageUrlFor(buildImageObj(partner.logo))
                                .auto("format")
                                .url()}
                              alt={partner.logo.alt ? partner.logo.alt : partner.name}
                            />
                          )}
                        </Link>
                      ) : (
                        <>
                          {partner.link ? (
                            <a href={partner.link} target="_blank" rel="noreferrer">
                              {partner.logo.asset.extension === 'svg' ? (
                                <Svg 
                                  src={partner.logo.asset.url} 
                                  description={partner.logo.alt ? partner.logo.alt : partner.name}
                                  uniquifyIDs={true}
                                />
                              ) : (
                                <img
                                  src={imageUrlFor(buildImageObj(partner.logo))
                                    .auto("format")
                                    .url()}
                                  alt={partner.logo.alt ? partner.logo.alt : partner.name}
                                />
                              )}
                            </a>
                          ) : (
                            <>
                              {partner.logo.asset.extension === 'svg' ? (
                                <Svg 
                                  src={partner.logo.asset.url} 
                                  description={partner.logo.alt ? partner.logo.alt : partner.name}
                                  uniquifyIDs={true}
                                />
                              ) : (
                                <img
                                  src={imageUrlFor(buildImageObj(partner.logo))
                                    .auto("format")
                                    .url()}
                                  alt={partner.logo.alt ? partner.logo.alt : partner.name}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </Container>
    </div>
  );
}

export default Partners;
